/* We wrap this around the App with wrapRootElement in gatsby-browser.js and gatsby-ssr.js  */

import React, { useState } from "react";

export const myContext = React.createContext();

const Provider = props => {
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <myContext.Provider value={{
      modalIsOpen,
      toggleModal: () => setIsOpen(!modalIsOpen)
    }}>
      {props.children}
    </myContext.Provider>
  )
};

const Context = ({ element }) => (
  <Provider>
    {element}
  </Provider>
);

export default Context;
